import { SchemaOf } from "yup";
import { FC, ReactElement } from "react";

import { EndpointAPITypesKeys } from "models";
import { Salesforce, salesforceSchema, SalesforceType } from "../connectors/salesforce";
import { RestApi, restAPISchema, RestAPIType } from "../connectors/rest-api";
import { Remarkety, remarketySchema, RemarketyType } from "../connectors/remarkety";
import { Omnisend, omnisendSchema, OmnisendType } from "../connectors/omnisend";
import { Iterable, iterableSchema, IterableType } from "../connectors/iterable";
import { GreenArrow, greenArrowSchema, GreenArrowType } from "../connectors/green-arrow";
import { Blueshift, blueshiftSchema, BlueshiftType } from "../connectors/blueshift";

export type EndpointAPIContentType =
  | SalesforceType
  | RestAPIType
  | OmnisendType
  | RemarketyType
  | BlueshiftType
  | IterableType
  | GreenArrowType;

type ConnectorsValidationsMap = Record<EndpointAPITypesKeys, SchemaOf<EndpointAPIContentType>>;

export const ConnectorsValidations: ConnectorsValidationsMap = {
  salesforce: salesforceSchema,
  restApi: restAPISchema,
  omnisend: omnisendSchema,
  remarkety: remarketySchema,
  blueshift: blueshiftSchema,
  iterable: iterableSchema,
  greenarrow: greenArrowSchema,
};

type EndpointAPIDispatcherProps = {
  apiType: EndpointAPITypesKeys;
  endpointOrdinal: number;
  handleDisableSubmit: (valid: boolean) => void;
};

type DispatchType = Record<EndpointAPITypesKeys, ReactElement>;

const EndpointAPIDispatcher: FC<EndpointAPIDispatcherProps> = ({ apiType, ...rest }) => {
  const APIMapDispatch: DispatchType = {
    salesforce: <Salesforce {...rest} />,
    restApi: <RestApi {...rest} />,
    omnisend: <Omnisend {...rest} />,
    remarkety: <Remarkety {...rest} />,
    blueshift: <Blueshift {...rest} />,
    iterable: <Iterable {...rest} />,
    greenarrow: <GreenArrow {...rest} />,
  };

  return APIMapDispatch[apiType];
};

export { EndpointAPIDispatcher };
