import { object, string, SchemaOf, number } from "yup";
import { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  FormField,
  Select,
  notification,
  useCall,
  isEmpty,
  useDidMount,
} from "@epcnetwork/core-ui-kit";

import { SelectOption } from "models";
import { requiredFieldText } from "constants/form.constants";
import { getGreenArrowLists } from "api";
import { InitialValues } from "../jobs-form.types";

import styles from "../job-form.module.css";

export const greenArrowSchema: SchemaOf<GreenArrowType> = object({
  apiKey: string().required(requiredFieldText),
  apiUrl: string().url("URL is invalid").required(requiredFieldText),
  listId: number().required(requiredFieldText),
});

type GreenArrowListType = SelectOption<string>;

export type GreenArrowType = {
  apiKey: string;
  apiUrl: string;
  listId: number;
};

type GreenArrowProps = {
  endpointOrdinal: number;
};

const GreenArrow: FC<GreenArrowProps> = ({ endpointOrdinal }) => {
  const { values, errors, setFieldValue, submitCount } = useFormikContext<InitialValues>();

  const [greenArrowLists, setGreenArrowLists] = useState<GreenArrowListType[]>([]);

  const greenArrowValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | GreenArrowType
    | undefined;

  const { apiKey, apiUrl, listId } = greenArrowValues || {};

  const getGreenArrowListsCall = useCall(getGreenArrowLists);
  getGreenArrowListsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    const optionsToSave: GreenArrowListType[] = payload.map(({ id, name }) => ({
      value: id ?? "",
      label: name,
    }));

    notification.success("GreenArrow lists fetched!", "Select your GreenArrow list.");
    setGreenArrowLists(optionsToSave);
  });
  getGreenArrowListsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const isListDisabled = !apiKey || !apiUrl || getGreenArrowListsCall.submitting;

  useDidMount(() => {
    if (!isListDisabled) {
      handleGetGreenArrowLists();
    }
  });

  const handleGetGreenArrowLists = () => {
    setGreenArrowLists([]);

    getGreenArrowListsCall.submit({
      data: {
        apiKey: apiKey?.trim(),
        apiUrl: apiUrl?.trim(),
      },
    });
  };

  const handleListSelect = (option: SelectOption<string>) => {
    const { value } = option || {};
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.listId`, value || "");
  };

  const errorsAny: any = errors;
  const error =
    (!isEmpty(errorsAny) &&
      !isEmpty(errorsAny.endpoints) &&
      errorsAny?.endpoints[endpointOrdinal]?.connector?.properties?.listId) ||
    "";
  const errorText = !!submitCount && error ? error : "";

  return (
    <>
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="API Key"
        placeholder="GreenArrow API Key"
      />

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiUrl`}
        label="API URL"
        placeholder="GreenArrow API URL"
      />

      <Select
        label="List"
        placeholder="Select GreenArrow list"
        name={`endpoints[${endpointOrdinal}].connector.properties.listId`}
        error={errorText}
        selectedOptionsKeys={listId}
        options={greenArrowLists}
        onChange={handleListSelect}
        disabled={isListDisabled}
      />
      <Button
        disabled={isListDisabled}
        onClick={handleGetGreenArrowLists}
        className={styles.btnDataExtensions}
      >
        Get GreenArrow lists
      </Button>
    </>
  );
};

export { GreenArrow };
